import axios from 'axios';
import log from './Log';
import { SessionState } from './SessionState';
const DebouceTimeout = 100;
export class CobrowsingClient {
    constructor(host, options, shadowRoot) {
        this.active = false;
        this.dirty = false;
        this.waiting = 0;
        this.endpoint = (path) => {
            return `${this.host}/api/session/${path}`;
        };
        /**
         * Register update callbacks
         */
        this.register = () => {
            this.createConsoleElement("register");
            this.active = true;
            const focusables = document.querySelectorAll("input, textarea, select, button, a");
            for (let i = 0; i < focusables.length; i++) {
                const focusable = focusables.item(i);
                for (const ev of ["focus", "blur"]) {
                    focusable.addEventListener(ev, async () => {
                        this.createConsoleElement("> update stream by ");
                        await this.debouceUpdate(true);
                    });
                }
            }
            this.observer = new MutationObserver(async (_) => {
                await this.debouceUpdate(true);
            });
            const target = document.querySelector('body');
            const config = { attributes: true, childList: true, characterData: true, subtree: true };
            this.observer.observe(target, config);
            for (const ev of ["scroll", "resize", "load"]) {
                window.addEventListener(ev, async () => {
                    await this.debouceUpdate(ev === "load");
                });
            }
        };
        /**
         * Unregister update callbacks
         */
        this.unregister = () => {
            this.createConsoleElement("unregister");
            this.active = false;
            if (this.updater) {
                clearTimeout(this.updater);
                this.updater = null;
            }
            if (this.observer) {
                this.observer.disconnect();
                this.observer = null;
            }
        };
        this.createConsoleElement("constructor from CobrowsingClient");
        this.options = options;
        this.host = host;
        this.shadowRoot = shadowRoot;
        this.updater = null;
        this.observer = null;
        //this.ping(true)
    }
    createConsoleElement(className) {
        const consoleElement = document.createElement('pre');
        consoleElement.className = className;
        if (this.shadowRoot) {
            this.shadowRoot?.appendChild(consoleElement);
        }
        else {
            var cobrowsing = document.querySelector('cobrowsing-client');
            cobrowsing?.appendChild(consoleElement);
        }
    }
    /**
     * Debouce update callbacks
     * @param includeDocument Include document in update
     */
    async debouceUpdate(includeDocument) {
        if (this.updater) {
            clearTimeout(this.updater);
        }
        if (includeDocument) {
            this.dirty = true;
        }
        if (this.active) {
            if (this.waiting > 10) {
                await this.dispatchUpdate(includeDocument || this.dirty);
                this.updater = null;
                this.waiting = 0;
                this.dirty = false;
            }
            else {
                this.updater = setTimeout(async () => {
                    await this.dispatchUpdate(includeDocument || this.dirty);
                    this.updater = null;
                    this.waiting = 0;
                    this.dirty = false;
                }, DebouceTimeout);
                this.waiting += 1;
            }
        }
    }
    /**
     * Create update
     * @param includeDocument Include document in update
     */
    createUpdate(includeDocument = false) {
        let documentUpdate = null;
        if (includeDocument) {
            /* apply transforms to window.document */
            // const nodes = document.querySelectorAll("*")
            // for (const node of nodes) {
            //     if (!node.hasAttribute("__observer_id"))
            //         node.setAttribute("__observer_id", Math.floor(Math.random() * 1000000).toString())
            // }
            // urlsToAbsolute(document.images);
            // urlsToAbsolute(document.querySelectorAll("link[rel='stylesheet']"));
            /* clone document and apply transforms */
            const clone = document.documentElement.cloneNode(true);
            /* remove scripts and frames */
            const noops = clone.querySelectorAll("script, noscript");
            for (let i = 0; i < noops.length; ++i) {
                const noop = noops.item(i);
                noop.parentNode?.removeChild(noop);
            }
            const frames = clone.querySelectorAll("iframe");
            for (let i = 0; i < frames.length; ++i) {
                const frame = frames.item(i);
                frame.src = '';
            }
            /* set input value attributes to input value */
            const inputs = clone.querySelectorAll("input, select");
            for (let i = 0; i < inputs.length; ++i) {
                const element = inputs.item(i);
                if (element.checked) {
                    element.setAttribute("checked", "checked");
                }
                if (element.value) {
                    if (element.type === 'password' || element.classList.contains('input__password')) {
                        /* do not send password or password length */
                        element.setAttribute("value", Array(16).join('*'));
                    }
                    else {
                        element.setAttribute("value", element.value);
                    }
                }
            }
            const textareas = clone.querySelectorAll("textarea");
            for (let i = 0; i < textareas.length; ++i) {
                const element = textareas.item(i);
                if (element.value) {
                    element.innerText = element.value;
                }
            }
            /* highlight focused element */
            const focused = document.activeElement;
            if (focused) {
                const observer_id = focused.getAttribute("__observer_id");
                if (observer_id) {
                    const focusClone = clone.querySelector('[__observer_id="' + observer_id + '"]');
                    if (focusClone) {
                        focusClone.style.outline = '2px dotted green';
                    }
                }
            }
            /* add base */
            const head = clone.querySelector('head');
            if (head) {
                const base = document.createElement('base');
                base.href = document.location.protocol + '//' + location.host;
                head.insertBefore(base, head.firstChild);
            }
            /* style overrides */
            clone.style.pointerEvents = 'none';
            clone.style.userSelect = 'none';
            clone.style.overflow = 'hidden';
            documentUpdate = clone.outerHTML;
        }
        /* store scroll position */
        let update = {
            clientWidth: Math.round(document.documentElement.scrollWidth || document.body?.scrollWidth),
            clientHeight: Math.round(document.documentElement.scrollHeight || document.body?.scrollHeight),
            scrollX: Math.round(window.scrollX || 0),
            scrollY: Math.round(window.scrollY || 0),
            document: documentUpdate
        };
        return update;
    }
    /**
     * Dispatch update
     * @param includeDocument Include document in update
     */
    async dispatchUpdate(includeDocument = false) {
        const update = this.createUpdate(includeDocument);
        axios.post(this.endpoint("update"), update, {
            withCredentials: true
        })
            .then((result) => { this.createConsoleElement(result); })
            .catch(async (error) => {
            if (error.response && error.response.status === 403) {
                await this.stop(true, `update failed: ${error}`);
            }
            else {
                log.error(error);
            }
        });
    }
    /**
     * Ping session
     * @param force
     * Force ping even if current state not active.
     * Primarily used for initializing to determine state.
     */
    async ping(force = false) {
        setTimeout(() => { this.ping(); }, 1000);
        if (this.active || force) {
            const endPointPing = `${this.endpoint("ping")}?active=${this.active}`;
            await axios.get(endPointPing, {
                withCredentials: true
            })
                .then(async (pong) => {
                this.createConsoleElement(`pong data ${pong.data}`);
                if (!this.active && pong.data === SessionState.ACTIVE) {
                    this.register();
                    this.debouceUpdate(true);
                }
                else if (this.active && pong.data === SessionState.STOPPED) {
                    await this.stop(true, `ping-pong stopped`);
                }
            })
                .catch(async (error) => {
                if (this.active) {
                    if (error.response && error.response.status === 403) {
                        await this.stop(true, `ping-pong failed: ${error}`);
                    }
                    else {
                        log.error(error);
                    }
                }
                setTimeout(() => { this.ping(); }, 10000);
            });
        }
    }
    /**
     * Start session
     */
    async start(sessionId = 0) {
        this.createConsoleElement("start from CobrowsingClient");
        if (!this.active) {
            const update = this.createUpdate(true);
            const endPointStart = `${this.endpoint("start")}?sessionId=${sessionId}`;
            await axios.post(endPointStart, update, {
                withCredentials: true
            })
                .then((response) => {
                this.ping(true);
                if (this.options?.onStarted) {
                    this.options.onStarted(response.data);
                    //If the sessionId is already known in the event of a page refresh, skip the intro screens
                    if (sessionId && this.options.onResumed) {
                        this.options.onResumed();
                    }
                }
            })
                .catch(error => { log.error(error); });
        }
    }
    /**
     * Stop session
     * @param notify will be used as input on `onStopped`-callback
     */
    async stop(notify = false, reason = "user") {
        this.createConsoleElement("stop in this.stop");
        const shouldNotify = this.active && notify;
        console.log('reason', reason);
        await axios.get(this.endpoint("stop"), {
            withCredentials: true
        })
            .then(log.debug)
            .catch(log.error);
        this.unregister();
        if (this.options?.onStopped) {
            this.options.onStopped(shouldNotify);
        }
    }
}
