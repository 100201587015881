import { CobrowsingClient } from './CobrowsingClient';
class CobrowsingClientElement extends HTMLElement {
    constructor() {
        super();
        this.client = null;
        this.start = () => {
        };
        this._active = false;
        this.stoppedEvent = new CustomEvent("cobrowsing.stopped");
        this.startedEvent = new CustomEvent("cobrowsing.started");
        this.resumedEvent = new CustomEvent("cobrowsing.resumed");
    }
    static get observedAttributes() {
        return ['host', 'theme'];
    }
    get host() {
        return this.getAttribute('host');
    }
    get theme() {
        return this.getAttribute('theme');
    }
    get getGtm() {
        if (this.theme === 'zeeuwse')
            return 'GTM-M95GHTX';
        return 'GTM-M3P9RR6';
    }
    get active() {
        return this._active;
    }
    connectedCallback() {
        let self = this;
        const shadowRoot = this.attachShadow({ mode: 'closed' });
        console.log("shadowRoot", shadowRoot);
        shadowRoot.innerHTML = `
        <script async="" src="https://www.googletagmanager.com/gtm.js?id=${this.getGtm}"></script>
        <script>
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })
            (window,document,'script','dataLayer', ${this.getGtm});
        </script>
        
        <style type="text/css">
            .cobrowsing {
                --color-primary: #609;
                --color-secondary: #09c;
                --color-interaction: #0079A1;
                --color-primary-dark: #4f0378;
                --color-secondary-dark: #07a;
            }
            .cobrowsing[data-theme='zeeuwse']{
                --color-primary: #eb7305;
                --color-secondary: #15a286;
                --color-interaction: #1e7966;
                --color-primary-dark: #ba5b02;
                --color-secondary-dark: #116454;
            }
            .cobrowsing--prestart, .cobrowsing--stopped, .cobrowsing--started, .cobrowsing--active {
                align-items: center;
                bottom: 0;
                display: none;
                flex-direction: column;
                left: 0;
                justify-content: start;
                padding-top: 0;
                position: fixed;
                pointer-events: none;
                right: 0;
                top: 0;
                z-index: 999;
            }
            .cobrowsing__backdrop {
                background-color: rgba(0, 0, 0, 0.6);
                height: 100vh;
                left: 0;
                opacity: 0;
                position: absolute;
                top: 0;
                transition: opacity 400ms ease-out;
                width: 100vw;
            }
            
            .cobrowsing--prestart.cobrowsing--visible {
                pointer-events: auto;
            }
            
            .cobrowsing--prestart.cobrowsing--visible .cobrowsing__backdrop,
            .cobrowsing--started.cobrowsing--visible .cobrowsing__backdrop {
                opacity: 1;
            }
            
            .cobrowsing--visible {
                display: flex !important;
            }
            
            .cobrowsing--active {
                border: 8px solid #0f8a14;
                justify-content: start;
            }
            
            .cobrowsing--started {
                pointer-events: all;
            }
            
            .cobrowsing--stop {
                align-content: center;
                align-items: center;
                background-color: #0f8a14;
                border-radius: 0 0 16px 16px;
                border:0;
                color: #fff !important;
                cursor: pointer;
                display: grid;
                font-family: Meta, sans-serif;
                font-size: 16px;
                font-weight: 700;
                grid-gap: 10px;
                grid-template-columns: auto auto auto;
                grid-row: span;
                line-height: 20px;
                padding: 2px 15px 7px 15px;
                pointer-events: all;
                text-decoration: none;
            }
            
            .cobrowsing--stop-icon {
                fill: #fff;
                height: 16px;
                width: 16px;
                display: inline-block;
            }
            
            .cobrowsing--stop-icon svg {
                height: 100%;
                width: 100%;
            }
            
            .cobrowsing--stopped_tooltip {
                background-color: #ecf9ec;
                box-sizing: border-box;
                box-shadow: 0 15px 20px 5px rgba(0, 33, 91, 0.25);
                color: #1bb562 !important;
                max-width: 100%;
                margin-top: 24px;
                padding: 11px 20px;
                pointer-events: all;
                text-decoration: none;
                width: 478px;
            }
            
            .cobrowsing__popup {
                background-color: #fff;
                box-sizing: border-box;
                border-radius: 10px;
                font-family: "Source Sans Pro", Arial;
                margin-top: 80px;
                max-width: 90%;
                max-height: 80vh;
                position: relative;
                width: 860px;
                -webkit-font-smoothing: antialiased;
                z-index: 1;
            }
            
            @media screen and max-width: 768px {
                .cobrowsing__popup {
                    margin-top: 40px;
                }
            }
            
            .popup__title {
                color: #000;
                font-family: "metabook_roman", "Meta", Arial;
                font-size: 20px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 24px;
                margin: 0;
            }
            
            .cobrowsing__popup p {
                font-size: 16px;
            }
            
            .cobrowsing__popup-header {
                display: flex;
                padding: 20px 40px;
                justify-content: space-between;
                border-bottom: 1px solid #BDBEC7;
            }
            
            .cobrowsing__popup-footer {
                display: flex;
                padding: 20px 40px;
                justify-content: flex-end;
                border-top: 1px solid #BDBEC7;
            }
            
            .popup__content {
                padding: 40px 40px;
                color: #353535;
                line-height: 24px;
                margin-bottom: 20px;
                font-size: 16px;
            }
            
            .popup__intro {
                margin-top: 0;
            }
            
            .popup__list {
                margin-top: 0;
                padding-left: 0;
            }
            .popup__list li {
                list-style-type: none;
                display: flex;
            }
            .popup__list li:before {
              content: '\\2022';
              margin-right: 10px;
            }
            
            .cobrowsing__close-popup {
              background: none;
              border: 0;
              cursor: pointer;
              padding: 0;
            }
            
            .cobrowsing--started_session {
                background-color: var(--color-interaction);
                color: white;
                display: inline-block;
                border-radius: 4px;
                padding: 16px 38px;
                font-size: 24px;
                font-weight: 700;
                line-height: 1;
                margin-top: 20px;
            }
            
            /* Button */
            .button {
                align-items: center;
                appearance: none;
                border: none;
                border-radius: 5px;
                color: white;
                cursor: pointer;
                background-color: var(--color-primary);
                box-shadow: 0 4px 20px -5px var(--color-primary);
                display: inline-grid;
                
                font-size: 16px;
                font-weight: 700;
                font-family: "Source Sans Pro", Arial;
                
                gap: 10px;
                grid-template-columns: auto auto;
                
                justify-content: space-between;
                justify-self: flex-start;
                padding: 18px 20px;
                text-align: left;
                text-decoration: none;
                transition: background 150ms ease-in-out;
                word-wrap: break-word;
            }
            
            .button__icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1.2em;
                height: 1.2em;
                transition: transform 150ms ease-in-out;
            }
            
            .button:hover {
                background-color: var(--color-primary-dark);
            }
 
            .button:hover .button__icon {
              transform: translateX(10px);
            }
        </style>
        
        <div class="cobrowsing" data-theme="${this.theme}">
            <div class="cobrowsing--active">
                <button class="cobrowsing--stop">
                    <span class="cobrowsing--stop-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 14.6C13.4359 14.6 14.6 13.4359 14.6 12C14.6 10.5641 13.4359 9.4 12 9.4C10.5641 9.4 9.4 10.5641 9.4 12C9.4 13.4359 10.5641 14.6 12 14.6ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1 12C1 12 5.92487 19 12 19C18.0751 19 23 12 23 12C23 12 18.0751 5 12 5C5.92487 5 1 12 1 12ZM2.77435 12C2.8242 12.0602 2.87681 12.123 2.9321 12.1882C3.45685 12.8068 4.21465 13.6296 5.14619 14.4485C7.05447 16.1262 9.4783 17.6 12 17.6C14.5217 17.6 16.9455 16.1262 18.8538 14.4485C19.7854 13.6296 20.5432 12.8068 21.0679 12.1882C21.1232 12.123 21.1758 12.0602 21.2256 12C21.1758 11.9398 21.1232 11.877 21.0679 11.8118C20.5432 11.1932 19.7854 10.3704 18.8538 9.55145C16.9455 7.87383 14.5217 6.4 12 6.4C9.4783 6.4 7.05447 7.87383 5.14619 9.55145C4.21465 10.3704 3.45685 11.1932 2.9321 11.8118C2.87681 11.877 2.8242 11.9398 2.77435 12Z" />
                        </svg>
                    </span>
                    <span>Meekijken stoppen</span>
                    <span class="cobrowsing--stop-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                            <path d="M11 9.586l5.657-5.657 1.414 1.414L12.414 11l5.657 5.657-1.414 1.414L11 12.414l-5.657 5.657-1.414-1.414L9.586 11 3.929 5.343 5.343 3.93z"/>
                        </svg>
                    </span>
                </button>
            </div>

            <div class="cobrowsing--prestart">
                <div class="cobrowsing__backdrop"></div>
                
                <div class="cobrowsing__popup">
                    <div class="cobrowsing__popup-header">
                    <h4 class="popup__title">Een van onze medewerkers wil met u meekijken</h4>
  
                      <button class="cobrowsing__close-popup">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                              <path d="M11 9.586l5.657-5.657 1.414 1.414L12.414 11l5.657 5.657-1.414 1.414L11 12.414l-5.657 5.657-1.414-1.414L9.586 11 3.929 5.343 5.343 3.93z"/>
                        </svg>
                      </button>
                    </div>

                    <div class="popup__content">
                        <p class="popup__intro">Heeft u hulp nodig in deze online omgeving? Wij kijken graag met u mee.</p>
                        <strong>Hoe werkt het?</strong>
                        <ul class="popup__list">
                          <li>U geeft toestemming aan onze medewerker om met u mee te kijken;</li>
                          <li>Onze medewerker kan alleen meekijken op deze online omgeving en ziet geen andere pagina’s of programma’s;</li>
                          <li>U kan het meekijken op elk moment stoppen door op het kruisje te klikken.</li>
                        </ul>
                    </div>

                    <div class="cobrowsing__popup-footer">
                      <button class="button cobrowsing--start-resume">
                          <span class="button__label">Start meekijken</span>
                          <span class="button__icon">
                              <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.10375 0.421759L9.14826 0.460662L13.5393 4.85173C13.5847 4.89713 13.618 4.95008 13.6391 5.00653C13.6513 5.03922 13.6595 5.07296 13.6635 5.10709C13.6668 5.13453 13.6675 5.16205 13.6656 5.18942C13.6631 5.2226 13.6576 5.25255 13.6491 5.28177C13.6406 5.31087 13.6288 5.33961 13.6138 5.36707C13.6096 5.37486 13.605 5.3827 13.6001 5.39042C13.5936 5.40069 13.5868 5.41064 13.5795 5.42035L13.5782 5.42196L13.5393 5.46648L9.14826 9.85755C8.9785 10.0273 8.70327 10.0273 8.53351 9.85755C8.3779 9.70194 8.36493 9.4577 8.49461 9.28731L8.53351 9.2428L12.1813 5.59334L0.768007 5.5938C0.527932 5.5938 0.333313 5.39918 0.333313 5.1591C0.333313 4.93904 0.496847 4.75716 0.709021 4.72838L0.768007 4.72441L12.1813 4.72401L8.53351 1.07541C8.36375 0.905653 8.36375 0.630421 8.53351 0.460662C8.68912 0.30505 8.93336 0.292083 9.10375 0.421759Z" fill="white"/>
                              </svg>
                          </span>
                      </button>
                    </div>
                </div>
            </div>
            
            <div class="cobrowsing--started">
                <div class="cobrowsing__backdrop"></div>
                
                <div class="cobrowsing__popup">
                  <div class="cobrowsing__popup-header">
                    <h4 class="popup__title">Activeringscode</h4>
                
                    <button class="cobrowsing__close-popup">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                        <path d="M11 9.586l5.657-5.657 1.414 1.414L12.414 11l5.657 5.657-1.414 1.414L11 12.414l-5.657 5.657-1.414-1.414L9.586 11 3.929 5.343 5.343 3.93z"/>
                      </svg>
                    </button>
                  </div>
                  
                    <div class="popup__content">
                        <div>Geef onderstaande code door, zodat onze medewerker met u mee kan kijken.</div>
                        <div class="cobrowsing--started_session">0</div>
                    </div>
                </div>
            </div>
            
            <div class="cobrowsing--stopped">
                <a class="cobrowsing--stopped_tooltip" href="#">
                    De medewerker van ${(this.theme === 'zeeuwse' ? "De Zeeuwse" : "De Goudse")} heeft de meekijksessie gestopt.
                </a>
            </div>
        </div>      
        `;
        console.log("Start of the consts");
        const cobrowsingActive = shadowRoot.querySelector(".cobrowsing--active");
        const cobrowsingStopped = shadowRoot.querySelector(".cobrowsing--stopped");
        const cobrowsingStarted = shadowRoot.querySelector(".cobrowsing--started");
        const cobrowsingStop = shadowRoot.querySelector(".cobrowsing--stop");
        const cobrowsingTooltip = shadowRoot.querySelector(".cobrowsing--stopped_tooltip");
        const cobrowsingStart = document.querySelector(".cobrowsing--start");
        const cobrowsingPrestartPopup = shadowRoot.querySelector(".cobrowsing--prestart");
        const cobrowsingStartResume = shadowRoot.querySelector(".cobrowsing--start-resume");
        const cobrowsingClosePopup = shadowRoot.querySelector(".cobrowsing__close-popup");
        console.log("End of the costs also window", window);
        window.dataLayer = window.dataLayer || [];
        if (cobrowsingClosePopup) {
            cobrowsingClosePopup.onclick = () => {
                showCobrowingPrestart(false);
            };
        }
        if (cobrowsingStart) {
            console.log("cobrowsingStart", cobrowsingStart);
            createConsoleElement("cobrowsing-start-function");
            cobrowsingStart.onclick = () => {
                showCobrowingPrestart(true);
            };
        }
        if (cobrowsingStartResume) {
            cobrowsingStartResume.onclick = () => {
                showCobrowingPrestart(false);
                this.client?.start();
                if (typeof window === "undefined")
                    return;
                window.dataLayer.push({
                    event: "click_button",
                    buttonId: "primary_button",
                    componentName: "Co-browsing",
                    buttonText: "Start meekijken",
                    click_url: undefined,
                    page: window.location?.pathname
                });
            };
        }
        if (cobrowsingStop) {
            cobrowsingStop.onclick = () => {
                this.client?.stop();
                if (typeof window === "undefined")
                    return;
                window.dataLayer.push({
                    event: "click_button",
                    buttonId: "primary_button",
                    componentName: "Co-browsing",
                    buttonText: "Stop meekijken",
                    click_url: undefined,
                    page: window.location?.pathname
                });
            };
        }
        if (cobrowsingTooltip) {
            cobrowsingTooltip.onclick = () => {
                showCobrowsingStopped(false);
            };
        }
        if (cobrowsingStarted) {
            cobrowsingStarted.onclick = () => {
                showCobrowsingStarted(false);
            };
        }
        function showCobrowingPrestart(visible) {
            console.log("showCobrowingPrestart", visible);
            if (!visible) {
                cobrowsingPrestartPopup?.classList.remove("cobrowsing--visible");
            }
            else {
                cobrowsingPrestartPopup?.classList.add("cobrowsing--visible");
            }
        }
        function showCobrowingActive(visible) {
            if (!visible) {
                cobrowsingActive?.classList.remove("cobrowsing--visible");
                document.body.classList.remove('cobrowsing-active');
            }
            else {
                cobrowsingActive?.classList.add("cobrowsing--visible");
                document.body.classList.add('cobrowsing-active');
            }
        }
        function showCobrowsingStopped(visible) {
            if (!visible) {
                cobrowsingStopped?.classList.remove("cobrowsing--visible");
            }
            else {
                cobrowsingStopped?.classList.add("cobrowsing--visible");
                setTimeout(function () {
                    showCobrowsingStopped(false);
                }, 10000);
            }
        }
        function showCobrowsingStarted(visible, session) {
            console.log("showCobrowsingStarted", visible, session);
            if (!visible) {
                cobrowsingStarted?.classList.remove("cobrowsing--visible");
            }
            else {
                shadowRoot.querySelector(".cobrowsing--started_session").innerText = (session || -1).toString();
                cobrowsingStarted?.classList.add("cobrowsing--visible");
            }
        }
        function showCobrowsingStart(visible) {
            console.log("showCobrowsingStart", visible);
            if (cobrowsingStart) {
                if (!visible) {
                    cobrowsingStart.style.display = 'none';
                }
                else {
                    cobrowsingStart.style.display = 'inline-flex';
                }
            }
        }
        function createConsoleElement(className) {
            const consoleElement = document.createElement('pre');
            consoleElement.className = className;
            shadowRoot.appendChild(consoleElement);
        }
        this.client = new CobrowsingClient(this.host || "", {
            onStopped: function (notify) {
                self._active = false;
                createConsoleElement("cobrowsing-onStopped");
                console.log("onStopped CobrowsingClient", notify);
                localStorage.removeItem("sessionId");
                if (notify) {
                    showCobrowsingStopped(true);
                }
                showCobrowsingStarted(false);
                showCobrowingActive(false);
                showCobrowsingStart(true);
                self.dispatchEvent(self.stoppedEvent);
            },
            onStarted: function (session) {
                createConsoleElement("cobrowsing-onStarted");
                self._active = true;
                localStorage.setItem("sessionId", session.toString());
                showCobrowsingStopped(false);
                showCobrowsingStarted(true, session);
                showCobrowingActive(true);
                showCobrowsingStart(false);
                self.dispatchEvent(self.startedEvent);
            },
            onResumed: function () {
                self._active = true;
                createConsoleElement("cobrowsing-onResumed");
                showCobrowsingStopped(false);
                showCobrowsingStarted(false);
                showCobrowingActive(true);
                showCobrowsingStart(false);
                self.dispatchEvent(self.resumedEvent);
            }
        }, shadowRoot);
        console.log("this.start CobrowsingClient");
        this.start = () => showCobrowingPrestart(true);
        if (localStorage.getItem("sessionId")) {
            const sessionId = parseInt(localStorage.getItem("sessionId") ?? '0');
            if (sessionId) {
                this.client.start(sessionId);
            }
        }
        createConsoleElement("cobrowsing-this-start");
    }
    disconnectedCallback() {
    }
    attributeChangedCallback(_attrName, _oldVal, _newVal) {
    }
    adoptedCallback() {
    }
}
// Registers custom element
window.customElements.define('cobrowsing-client', CobrowsingClientElement);
